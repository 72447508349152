import React from "react";
import { Text, Heading } from "./..";

const PrivacyPolicy = (props) => {
    return (
        <div {...props} className="min-h-screen">
            <div className="flex flex-col py-[5%] md:p-5 bg-gradient w-[100%] min-h-screen">
                <div className="ml-[5%] sm:ml-[15%] mt-[5%] sm:mt-[10%]">
                    <Heading size="s" as="h1" className="uppercase italic md:max-w-[75%] font-bold text-2xl sm:text-3xl md:text-4xl min-w-min sm:min-w-max my-5">
                        <>
                            Privacy Policy
                        </>
                    </Heading>
                    <Text as="p" className="w-[90%] md:w-[80%] my-5">
                        Welcome to the privacy policy for Exhibits. We believe that tech doesn't have to be invasive for it to be useful! We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you use our app and tell you about your privacy rights and how the law protects you.
                    </Text>
                    <Text as="p" className="w-[48%] md:w-[50%] text-orange-500 italic">
                        Data we collect and account deletion
                    </Text>
                    <Text as="p" className="w-[90%] md:w-[80%] my-5">
                        We use your email and username to enable you to log in and use our app. We do not use this data for any other purpose. We do not share your data with any third parties. Your data is only used to provide you with services within our app. We retain your email and username for as long as your account is active. If you wish to delete your account, your data will be permanently deleted from our servers. You have the right to access the personal data we hold about you and to ask that your personal data be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
                    </Text>
                    <Text as="p" className="w-[48%] md:w-[50%] text-orange-500 italic">
                        Contact us
                    </Text>
                    <Text as="p" className="w-[90%] md:w-[80%] my-5">
                        For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by email at info@exhibits-app.co.uk
                    </Text>

                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
