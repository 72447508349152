import React from "react";
import { Text } from "./..";

interface Props {
  className?: string;
  title?: string;
  subtitle?: string;
}

export default function TitleSubtitleText({
  title,
  subtitle,
  ...props
}: Props) {
  return (
    <div {...props}>
      <Text as="p" className="text-orange-500 italic text-base w-[90%] md:w-[60%]">
        {title}
      </Text>
      <Text as="p" className="w-[90%] md:w-[60%]">{subtitle}</Text>
    </div>
  );
}
