import React from "react";
import { Helmet } from "react-helmet";
import { Img, Heading, Text } from "../../components";
import CategoryButtonList from "../../components/CategoryButtonList";
import LongformLogo from "../../components/LongformLogo";
import SectionOneTextColumn from "../../components/SectionOneTextColumn";
import TitleSubtitleText from "../../components/TitleSubtitleText";

export default function LandingPagePage() {
  return (
    <>
      <Helmet>
        <title>Exhibits App</title>
        <meta name="description" content="Welcome to Exhibits App" />
      </Helmet>
      <div className="w-full bg-white-A700">
        <div>
          <div>
            <div className="flex flex-col py-[5%]  bg-gradient w-[100%] ">
              <LongformLogo className="md:flex md:flex-col md:justify-start md:items-start w-[40%] sm:w-[30%] md:w-[25%] lg:w-[20%] xl:w-[17%] ml-[5%] sm:ml-[15%] object-cover" />
              <SectionOneTextColumn className="flex flex-col w-[90%] sm:w-[80%] mt-[5%] ml-[5%] sm:ml-[15%] gap-[17px] md:mt-[7.5%] md:mb-[5%] md:min-w-[70%]" />
            </div>
            <div>
              <div className="flex flex-col bg-red-50 ">
                <div className="flex flex-col pr-14 gap-[17px] py-[5%] md:pt-5 ml-[5%] sm:ml-[15%]">
                  <Heading
                    as="h1"
                    className="uppercase md:max-w-[75%] font-bold text-2xl sm:text-3xl md:text-4xl min-w-min sm:min-w-max"
                  >
                    <>something <br /> for everyone</>
                  </Heading>
                  <Text
                    size="s"
                    as="p"
                    className="w-[90%] md:w-[60%] text-orange-500 italic"
                  >
                    Browse through the most comprehensive collection of London exhibitions. Here are just some of the
                    different types of exhibitions you can expect to find
                  </Text>
                  <CategoryButtonList className=" flex-col sm:justify-start sm:items-stretch md:gap-[25px] md:mr-[15%] mt-[3%]" />
                </div>
              </div>
              <div className="flex md:flex-col justify-between items-start md:justify-center py-[5%]  sm:pt-5  bg-white-A700">
                <div className="flex flex-col justify-start w-[90%] md:w-[75%] sm:w-[70%] ml-[5%] sm:ml-[15%] md:min-w-[75px] mr-[5%]">
                  <Heading className="uppercase md:max-w-[75%] font-bold text-2xl sm:text-3xl md:text-4xl min-w-min sm:min-w-max">
                    Why Exhibits
                  </Heading>
                  <TitleSubtitleText
                    title="Simple to use"
                    subtitle="Bored of having to scroll through endless social media to find hidden gems? Finding interesting exhibitions to visit in London shouldn't be hard, and it shouldn't take long either. Exhibits has it all, without the faff"
                    className="flex flex-col mt-[37px] gap-[5px]"
                  />
                  <TitleSubtitleText
                    title="Comprehensive"
                    subtitle="We're the only people bringing you ALL London exhibitions - not just ones about Art (though we have plenty of them too!). Each one is categorised, making it easier to find more of what you love"
                    className="flex flex-col mt-[37px] gap-[5px]"
                  />
                  <TitleSubtitleText
                    title="Built by exhibition lovers, for everyone"
                    subtitle="Started by two sisters who grew up going to exhibitions on the weekends, we built an app we'd actually like to use. With over 250 galleries and museums in London, we believe there's something for everyone to enjoy. It's just a matter of finding it in time"
                    className="flex flex-col mt-[37px] gap-[5px]"
                  />
                </div>

              </div>
              <div className="flex flex-col  bg-orange-500">
                <div className="flex flex-col pr-14 py-[5%] md:pt-5 ml-[5%] sm:ml-[15%]">
                  <Heading
                    as="h1"
                    className="uppercase md:max-w-[75%] font-bold text-2xl sm:text-3xl md:text-4xl min-w-min sm:min-w-max"
                  >
                    contact us
                  </Heading>
                  <a href="mailto:info@exhibits-app.co.uk" className="text-sm mb-3">info@exhibits-app.co.uk</a>
                  <Text className="!text-white-A700 text-sm italic">
                    Let's be social: <br />
                  </Text>
                  <a href="https://www.facebook.com/profile.php?id=61555065508487" target="_blank" className="italic !text-white-A700 text-sm">@exhibits-app (Facebook/Meta)</a>
                  <a href="https://www.instagram.com/exhibits_app/" target="_blank" className="italic !text-white-A700 text-sm">@exhibits_app (Instagram)</a>
                  <a href="/privacy-policy" className="text-sm mb-3">Privacy Policy</a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
