import React from "react";
import { Button } from "./..";

interface Props {
  className?: string;
}

export default function CategoryButtonList({ ...props }: Props) {
  return (
    <div {...props}>
      <div className="flex flex-row flex-wrap justify-start gap-2.5 flex-1  lg:max-w-[75%] ">
        <Button className="h-[42px] sm:min-w-[178px] max-w-[178px] hover:cursor-default">Family friendly</Button>
        <Button className="h-[42px] sm:min-w-[158px] max-w-[158px] hover:cursor-default">Photography</Button>
        <Button className="h-[42px] sm:min-w-[119px] max-w-[119px] hover:cursor-default">Black Art</Button>
        <Button className="h-[42px] sm:min-w-[158px] max-w-[158px] hover:cursor-default">Female artist</Button>
        {/* </div>
      <div className="flex md:flex-row sm:flex-col justify-start gap-5  md:gap-[2%] md:mr-[5%] sm:gap-2.5 flex-1 md:max-w-[75%] hover:cursor-default"> */}
        <Button className="h-[42px] sm:min-w-[119px] max-w-[119px] hover:cursor-default">Science</Button>
        <Button className="h-[42px] sm:min-w-[119px] max-w-[119px] hover:cursor-default">History</Button>
        <Button className="h-[42px] sm:min-w-[119px] max-w-[119px] hover:cursor-default">Fashion</Button>
        <Button className="h-[42px] sm:min-w-[119px] max-w-[119px] hover:cursor-default">Sports</Button>
        <Button className="h-[42px] sm:min-w-[158px] max-w-[158px] hover:cursor-default">+ Loads more</Button>
      </div>
    </div>
  );
}
