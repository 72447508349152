import React from "react";
import { Img } from "./..";

interface Props {
  className?: string;
}

export default function LongformLogo({ ...props }: Props) {
  return <Img {...props} src="images/exhibits_longform_logo_cropped.png" alt="Exhibits logo which is a white letter E, black text for the remainder of the word over a bright orange rectangle." />;
}
