import React from "react";
import { Text, Heading, Img } from "./..";

interface Props {
  className?: string;
  title?: string;
  description?: string;
  orangeText?: string;
}

export default function SectionOneTextColumn({
  title = "NEVER MISS AN EXHIBITION AGAIN!",
  description = "London has an impressive number of cool exhibitions going on each week... how many do you know of? Exhibits has all the info you need in one place so you can spend less time planning, more time enjoying",
  orangeText = "We've poured a lot of good vibes and great intentions into this awesome app...Click below to download it and feel the joy for yourself",
  ...props
}: Props) {
  return (
    <div {...props}>
      <Heading size="s" as="h1" className="uppercase italic md:max-w-[75%] font-bold text-2xl sm:text-3xl md:text-4xl min-w-min sm:min-w-max">
        <>
          Never miss an<br />exhibition again
        </>
      </Heading>
      <Text as="p" className="w-[90%] md:w-[60%]">{description}</Text>
      <Text as="p" className="w-[90%] md:w-[60%] text-orange-500 italic">
        {orangeText}
      </Text>
      <div className="flex space-x-0">
        <a href="https://apps.apple.com" target="_blank" rel="noopener noreferrer" className="w-[40%] sm:w-[35%] md:w-[30%] lg:w-[25%] xl:w-[17%]">
          <Img src="images/download_on_app_store_button.svg" alt="black button with apple logo on left, and text saying download on the app store" className="h-full" />
        </a>
        <a href="https://play.google.com" target="_blank" rel="noopener noreferrer" className="w-[40%] sm:w-[35%] md:w-[30%] lg:w-[25%] xl:w-[17%]">
          <Img src="images/google_play_button.png" alt="black button with google play logo on left, and text saying get on the google play store" className="h-full" />
        </a>
      </div>
      <div className="w-[60%] md:w-[90%] lg:w-[90%] flex flex-col md:flex-row space-y-4 md:space-y-0">
        <Img src="images/phone_screen_1.png" alt="Phone screen showing several different categories such as family friendly, photography, and black art." className="w-full md:w-1/3  md:mr-0" />
        <Img src="images/phone_screen_2.png" alt="Phone screen showing a collage image of a black man in a hat. Underneath is the title of the exhibition and general information about it." className="w-full md:w-1/3  md:mr-0" />
        <Img src="images/phone_screen_3.png" alt="Phone screen showing a grid of different venues in London including the National Gallery, Tate Modern, and British Library." className="w-full md:w-1/3" />
      </div>

    </div >
  );
}
