import React from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "pages/NotFound";
import LandingPage from "pages/LandingPage";
import PrivacyPolicy from "components/PrivacyPolicy";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <LandingPage /> },
    { path: "*", element: <NotFound /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    // {
    //   path: "landingpage",
    //   element: <LandingPage />,
    // },
  ]);

  return element;
};

export default ProjectRoutes;
